<template>
	<div class="bg-white h100">
		<header class="container-fluid px-0">
			<nav
				class="navbar navbar-expand-lg navbar-light bg-dark fixed-top"
				style="min-width: 260px; min-height: 68px">
				<div class="container">
					<!-- w-50 d-inline mr-0 -->
					<a
						class="navbar-brand w-50 d-inline mr-0 pointer"
						:class="{ 'position-absolute': !['lg', 'xl'].includes(windowWidth)}"
						@click="$router.push({ name: getRedirectName('Store') })">
						<div
							:id="logoIds().navbarDark"
							class="my-1 float-left" /></a>
					<div
						v-if="showSteps"
						class="ml-auto d-flex">
						<div class="row d-none d-md-flex">
							<div
								v-for="(item, index) in Object.keys(steps)"
								:key="index"
								class="col text-center">
								<i
									:class="(step > index || successStep) ? 'fas fa-check-circle' : step===index ? 'far fa-dot-circle' : ' far fa-circle'"
									class="fa-2x" /><br>
								<div style="min-width: 80px;">
									<span style="font-size: 10px;">{{ translate(item) }}</span>
								</div>
							</div>
						</div>
						<div class="row justify-content-end d-flex d-md-none mr-2">
							<div
								v-for="(item, index) in Object.keys(steps)"
								:key="index"
								class="text-center">
								<i
									v-if="step===index"
									class="fa-2x far fa-dot-circle" /><br>
								<span
									v-if="step===index"
									style="font-size: 10px;">{{ translate(item) }}</span>
							</div>
						</div>
					</div>
					<b-navbar-nav>
						<CountryDropdown
							v-if="['Preregister','SoftRegister', 'RegisterOld', 'Renewal'].includes(currentModule) && !$route.name.includes('Success')"
							class="white-dropdown flow-dropdown ml-3" />
						<RegisterCountryDropdown
							v-if="['Register'].includes(currentModule) && !$route.name.includes('Success')"
							class="white-dropdown flow-dropdown ml-3" />
					</b-navbar-nav>
				</div>
			</nav>
		</header>
		<main
			:class="noContainer ? '' : 'container'"
			class="mt-4 h100">
			<router-view
				:key="$route.fullPath"
				class="pt-5" />
		</main>
		<footer
			id="about-us"
			class="aboutUsStore">
			<b-card
				class="border-0 rounded-0 bg-dark mb-0">
				<div
					class="row p-2 mx-auto mt-3 container">
					<div class="col">
						<div class="row mb-0">
							<div class="col-md-6 col-lg-5">
								<div
									class="row"
									:class="['xs', 'sm'].includes(windowWidth) ? 'mb-4 text-center' : 'mb-3'">
									<div class="col mb-2">
										<img
											class="img-fluid w-60"
											:src="`${S3_PATH}/assets/footer.png`">
									</div>
								</div>
							</div>
							<div class="col-md-6 col-lg-7 d-flex flex-column">
								<div class="row">
									<div class="col-md col-lg-auto ml-auto">
										<div
											:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : 'py-2'"
											class="text-white">
											<p class="px-0 mb-0 headline-small">
												{{ translate('support') }}
											</p>
											<hr
												:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
												class="border-white ml-0 mt-0 mb-0"
												style="width: 25px; border-width: 1px">
										</div>
										<div
											:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mt-2 mb-4' : ''"
											class="text-transparency pt-2">
											<p class="text-white body-text mb-0">
												{{ support }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr
							class="border-transparency"
							style="border-width: 1px">
						<div
							:class="{ 'text-center' : ['xs', 'sm'].includes(windowWidth)}"
							class="row white-transparency font-weight-light">
							<div class="col-12 col-sm-4">
								<p class="captions">
									© {{ currentYear }} {{ companyName }} {{ translate('all_right_reserved') }}
									<br>{{ translate('footer_age') }}
								</p>
								<p
									v-if="ENTITY_MESSAGES_COUNTRIES.includes(country)"
									class="captions">
									<span class="white-transparency-title">{{ translate('payment_entity_title') }}</span>
									<br>{{ translate('payment_entity_text') }}
								</p>
							</div>
							<div class="col-12 col-sm-8 captions">
								{{ translate('disclaimer') }}
							</div>
						</div>
					</div>
				</div>
			</b-card>
		</footer>
	</div>
</template>

<script>
import {
	STORAGE_STEPS,
	PREREGISTER_STEPS,
	SOFTREGISTER_STEPS,
	REGISTER_STEPS,
	REGISTER_OLD_STEPS,
	BECOME_DISTRIBUTOR_STEPS,
	CHECKOUT_OLD_LOGGED_STEPS,
	CHECKOUT_OLD_GUEST_STEPS,
	RENEWAL_STEPS,
	TRANSFER_FEE_STEPS,
	CHECKOUT_GUEST_STEPS,
	PURCHASE_LOGGED_STEPS,
} from '@/settings/Wizard';
import {
	Store, Products, Grids, Purchase,
} from '@/translations';
import { S3_PATH } from '@/settings/Images';
import { distributor } from '@/settings/Roles';
import { ENTITY_MESSAGES_COUNTRIES } from '@/settings/Country';
import CommonMix from '@/views/RegisterOld/mixins/Common';
import AgencySite from '@/mixins/AgencySite';
import preregisterStore from '@/views/Preregister/store';
import WindowSizes from '@/mixins/WindowSizes';
import registerStore from '@/views/Register/store';
import Cart from '@/util/Cart';
import CountryDropdown from '../Main/CountryDropdown';
import RegisterCountryDropdown from '@/views/Register/RegisterCountryDropdown';
import { AGENCY_REPLICATED_SITE_NAME } from '@/settings/Replicated';
import EmergencyMode from '@/util/EmergencyMode';

const cookie = require('vue-cookie');

const SUCCESS_STEPS = [
	'PreregisterSuccess',
	'SoftRegisterSuccess',
	'RegisterSuccess',
	'RegisterOldSuccess',
	'BecomeDistributorSuccess',
	'CheckoutOldSuccess',
	'CheckoutSuccess',
	'AgencyDirectCheckoutSuccess',
	'AgencyNootropicsCheckoutSuccess',
	'RenewalSuccess',
	'TransferFeeSuccess',
	'PurchaseSuccess',
	'ChangePaymentMethodSuccess',
];

export default {
	name: 'PurchaseLayout',
	components: { RegisterCountryDropdown, CountryDropdown },
	messages: [Store, Products, Grids, Purchase],
	mixins: [CommonMix, WindowSizes, AgencySite],
	data() {
		return {
			S3_PATH,
			support: process.env.VUE_APP_COMPANY_EMAIL,
			currentYear: new Date().getFullYear(),
			companyName: process.env.VUE_APP_COMPANY_NAME,
			ENTITY_MESSAGES_COUNTRIES,
			createCart: new Cart(),
			removeCart: new Cart(),
			cookie,
			agencyReplicatedName: AGENCY_REPLICATED_SITE_NAME,
		};
	},
	computed: {
		showSteps() {
			return !['Purchase', 'Checkout', 'AgencyDirectCheckout', 'AgencyNootrpicsCheckout', 'ChangePaymentMethodMain', 'BecomeAffiliateMain', 'AffiliateAutoshipInfo'].includes(this.currentModule);
		},
		noContainer() {
			return ['BecomeAffiliateMain'].includes(this.currentModule);
		},
		steps() {
			let steps = PREREGISTER_STEPS;
			switch (this.currentModule) {
			case 'Preregister':
				steps = PREREGISTER_STEPS;
				break;
			case 'SoftRegister':
				steps = SOFTREGISTER_STEPS;
				break;
			case 'RegisterOld':
				steps = REGISTER_OLD_STEPS;
				break;
			case 'Register':
				steps = REGISTER_STEPS;
				break;
			case 'BecomeDistributor':
				steps = BECOME_DISTRIBUTOR_STEPS;
				break;
			case 'Renewal':
				steps = RENEWAL_STEPS;
				break;
			case 'TransferFee':
				steps = TRANSFER_FEE_STEPS;
				break;
			case 'Checkout':
			case 'AgencyDirectCheckout':
			case 'AgencyNootropicsCheckout':
				steps = CHECKOUT_GUEST_STEPS;
				break;
			case 'Purchase':
				steps = PURCHASE_LOGGED_STEPS;
				break;
			case 'CheckoutOld':
				if (this.$user.auth()) steps = CHECKOUT_OLD_LOGGED_STEPS;
				else steps = CHECKOUT_OLD_GUEST_STEPS;
				break;
			default:
				steps = PREREGISTER_STEPS;
			}
			return steps;
		},
		step() {
			const { name } = this.$route;
			return Object.keys(this.steps).indexOf(name);
		},
		successStep() {
			try	{
				const { name } = this.$route;
				return SUCCESS_STEPS.includes(name);
			} catch (error) {
				return false;
			}
		},
		cartRemovalResponse() {
			try {
				const { response } = this.removeCart.data.response.data;
				return response;
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		country(value, oldValue) {
			if (this.$user.auth()) {
				this.country = this.$user.details().country.iso_code_2;
				this.$forceUpdate();
				return null;
			}
			if (oldValue !== null) {
				this.setNewCartId().finally(async () => {
					await preregisterStore.dispatch('removeRegister');
					await this.$router.replace({ name: this.currentModule }).catch(() => {});
					// Due to importing two different versions of VSG's script, we need to reload the page when
					// 	a different country is selected to reset external script imports.
					// TODO:
					// 	- Perhaps we can limit this to only detect changes between EU and non-EU countries
					// 	- Remove this after transitioning to single version of credit card payments
					window.location.reload();
				});
			}
			return this.$forceUpdate();
		},
		registerCountry(value, oldValue) {
			if (oldValue !== null) {
				this.setNewCartId().finally(async () => {
					await preregisterStore.dispatch('removeRegister');
					await this.$router.replace({ name: this.currentModule }).catch(() => {});
					// Due to importing two different versions of VSG's script, we need to reload the page when
					// 	a different country is selected to reset external script imports.
					// TODO:
					// 	- Perhaps we can limit this to only detect changes between EU and non-EU countries
					// 	- Remove this after transitioning to single version of credit card payments
					window.location.reload();
				});
			}

			return this.$forceUpdate();
		},
	},
	mounted() {
		if (!STORAGE_STEPS) {
			window.addEventListener('beforeunload', (event) => {
				event.returnValue = true;
			});
			window.addEventListener('unload', () => {
				preregisterStore.dispatch('removeRegister');
			});
		}

		if (this.currentModule === 'RegisterOld' || this.currentModule === 'Register') {
			const { placement, parent, spillover } = this.$store.getters['position/positionData']('register');
			if (typeof placement !== 'undefined' && typeof parent !== 'undefined') {
				registerStore.dispatch('setPositionForRegister', { leg: placement, parent, spillover });
			}
		}
		const [body] = document.getElementsByTagName('body');
		body.setAttribute('class', 'bg-white');
		body.setAttribute('class', 'h100');
	},
	beforeDestroy() {
		const [body] = document.getElementsByTagName('body');
		body.classList.remove('bg-white');
		body.classList.remove('h100');
	},
	methods: {
		async setNewCartId() {
			await this.removeCart.removeCart(cookie.get('cart_id'));
			if (typeof this.cartRemovalResponse.cartId !== 'undefined' && cookie.get('cart_id') !== this.cartRemovalResponse.cartId) {
				this.cookie.delete('cart_id');
				this.cookie.set('cart_id', this.cartRemovalResponse.cartId, { expired: `${this.EXPIRATION_VALUE}${this.EXPIRATION_TIME}` });
			} else {
				await this.createCart.createCart();
				this.cookie.delete('cart_id');
				this.cookie.set('cart_id', this.createCart.data.response.data.response.cart_id, { expired: `${this.EXPIRATION_VALUE}${this.EXPIRATION_TIME}` });
			}
		},
	},
	async beforeRouteEnter(to, from, next) {
		let emergencyMode = false;
		try {
			const emergencyModeUtil = new EmergencyMode();
			const response = await emergencyModeUtil.checkEmergencyMode();
			emergencyMode = response.response.emergency_mode;
		} catch (error) {
			console.error('Failed to check emergency mode:', error);
		}

		next((vm) => {
			vm.emergencyMode = emergencyMode;
			vm.emergencyModeChecked = true;

			if (emergencyMode) {
				vm.$router.replace({ name: 'Store' });
				return;
			}

			if (to.name.search('Agency') >= 0) {
				if (vm.$replicated === null || vm.$replicated.replicatedId() !== vm.agencyReplicatedName) {
					vm.$replicated.siteOn(vm.agencyReplicatedName);
				}
			} else if (vm.$replicated.replicatedId() === vm.agencyReplicatedName) {
				vm.$replicated.siteOff();
				vm.$replicated.siteReplicatedSponsorOff();
			}
		});
	},
	beforeRouteLeave(to, from, next) {
		if (
			SUCCESS_STEPS.includes(from.name)
			|| (
				(from.name.includes('Renewal'))
				&& (!this.$user.auth() || this.$user.details().type !== distributor)
			)
			|| (
				(from.name.includes('RegisterOld') || from.name.includes('Register'))
				&& (this.$user.auth() && this.$user.details().type !== distributor)
			)
			|| (
				from.name.includes('BecomeDistributor')
				&& (!this.$user.auth() || !['affiliate', 'customer'].includes(this.$user.details().type))
			)
			|| (
				from.name.includes('Purchase')
				&& (!this.$user.auth())
			)
			|| (
				from.name.includes('ChangePaymentMethod')
				&& (!this.$user.auth())
			)
			|| (
				from.name.includes('BecomeAffiliate')
			)
			|| (
				from.name.includes('GiftOrderSuccess')
			)
			|| (
				from.name.includes('FillAffiliateAutoshipInfo')
			)
		) {
			next();
		} else {
			const alert = new this.$Alert();
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
			};
			alert.confirmation(this.translate('leave_register_title'), '', options).then(() => {
				this.removeRegister().then(() => {
					this.$store.dispatch('position/removeRegister');
				}).catch(() => {});
				return next();
			}).catch(() => {});
		}
	},
};
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	p {
		font-family: 'Montserrat', sans-serif !important;
	}
	.aboutUsStore {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	body {
		min-height: 100% !important;
		height: 100% !important;
		background: white;
	}
	html {
		min-height: 100% !important;
		height: 100% !important;
	}
	.h100 {
		min-height: 80.5vh !important;
	}
	.bottom {
		position: relative;
		/*bottom: 0;*/
		width: 100%;
	}
	.footer-links {
		margin-top: -10px;
	}

	.bg-black{
		background-color: black;
	}
	.white-transparency-title {
		color: #bdbdbd;
	}
	.white-transparency {
		color: #939393;
	}
	.text-transparency {
		color: #ffffff90;
	}
	.border-transparency {
		border-color: #6c6c6cbf;
	}
	.bg-footer {
		background-color:#2b2b2b;
	}
	.w-60 {
		max-width: 250px;
	}
	@media only screen and (max-width: 480px) {
		div#theme.Velovita #logo_white, div#theme.Velovita #jns_logo_white {
			width: auto;
			height: 30px;
		}
	}
</style>
