<template>
	<AppHeaderDropdown
		:class="windowWidth === 'xs' ? 'dropdown-offset-right': ''"
		right
		no-caret>
		<template slot="header">
			{{ getRegisterCountry() }}
			<img
				:src="getFlag(getRegisterCountry(), true)"
				class="ml-1">
		</template>
		<template slot="dropdown">
			<b-dropdown-header
				tag="div"
				class="text-center">
				<strong>{{ translate('market') }}</strong>
			</b-dropdown-header>
			<b-dropdown-item
				v-for="(item, key) in activeCountries"
				:key="key"
				class="w-100"
				@click="updateCountry(item.code)">
				<img
					:src="getFlag(item.code, true)"
					class="mr-1">{{ translate(item.code.toLowerCase()) }}
				<i :class="getRegisterCountry() === item.code ? 'fa fa-check pull-left text-primary ml-1' : ''" />
			</b-dropdown-item>
		</template>
	</AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
import { DEFAULT_COUNTRY } from '@/settings/Country';
import { Countries } from '@/translations';
import Country from '@/util/Country';
import WindowSizes from '@/mixins/WindowSizes';
import { CREATE_CART } from '@/config/endpoint';
import { api } from '@/config/axios/index';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import ReplicatedSite from '@/util/ReplicatedSite';

const cookie = require('vue-cookie');

export default {
	name: 'RegisterCountryDropdown',
	messages: [Countries],
	components: {
		AppHeaderDropdown,
	},
	mixins: [WindowSizes],
	data() {
		return {
			languageStore: process.env.VUE_APP_LANGUAGE_STORE,
			activeCountries: {},
			replicated: new ReplicatedSite(),
		};
	},
	beforeMount() {
		this.getActiveCountries().then(() => {
			const storedCountry = this.getRegisterCountry();
			const activeCountriesCodes = Object.keys(this.activeCountries).map((item) => this.activeCountries[item].code);
			if (storedCountry && this.registerCountry !== storedCountry) {
				this.registerCountry = storedCountry;
				this.$forceUpdate();
			}
			if (!storedCountry || !activeCountriesCodes.includes(storedCountry)) {
				this.getCountryByIP().then((response) => {
					if (activeCountriesCodes.includes(response)) {
						this.registerCountry = response;
					} else {
						this.registerCountry = DEFAULT_COUNTRY;
					}
					this.$forceUpdate();
				}).catch(() => {
					this.registerCountry = DEFAULT_COUNTRY;
					this.$forceUpdate();
				}).finally(() => {
					if (cookie.get('cart_id') === null) {
						const { method, endpoint } = CREATE_CART;
						api[method](endpoint).then((response) => {
							cookie.set('cart_id', response.data.response.cart_id, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` });
						});
					}
				});
			}
		});
	},
	methods: {
		getActiveCountries() {
			const replicatedUsername = this.$replicated.replicatedId();
			const countries = new Country();
			return countries.getCountries({ replicated_site: replicatedUsername }).then((response) => {
				try {
					this.activeCountries = response.map((item) => ({ id: item.id, code: item.attributes.code, name: item.attributes.name }));
				} catch (error) {
					this.activeCountries = [];
				}
			});
		},
		getCountryByIP() {
			const countries = new Country();
			return countries.setCountryByIP().then((response) => response).catch((error) => Promise.reject(error));
		},
		getFlag(src, isSmall) {
			let path = 'header';
			if (isSmall === true) {
				path = 'select';
			}
			try {
				/* eslint-disable global-require */
				/* eslint-disable import/no-dynamic-require */
				return require(`@/assets/images/common/countries/${path}/${src}.png`);
			} catch (error) {
				return require(`@/assets/images/common/countries/${path}/default.png`);
			}
		},
		updateCountry(code) {
			this.registerCountry = code;
			this.country = code;

			// Update replicated info, because hide_store key changes on country changes
			if (!this.$user.auth() && this.$replicated.replicatedId()) {
				this.replicated.getReplicatedSiteNav(this.$replicated.replicatedId()).then(() => {
					this.$replicated.siteReplicatedSponsorOn(this.replicated.data.response.data.response.user);
				});
			}

			this.$forceUpdate();
		},
	},
};
</script>

<style>
.dropdown-menu-right {
	max-height:  300px !important;
	width:  250px !important;
	overflow-y: scroll !important;
	overflow-x: hidden !important;
}

.dropdown-offset-right .dropdown-menu-right{
	left: -5px !important;
}
</style>
